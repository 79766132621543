// Generated by Framer (b2eeca0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["C0KN0GIY2", "LSaZgZjzA", "YMQvVxjjb"];

const variantClassNames = {C0KN0GIY2: "framer-v-dmis4g", LSaZgZjzA: "framer-v-1abpjqe", YMQvVxjjb: "framer-v-h79r71"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Desktop: "C0KN0GIY2", Phone: "YMQvVxjjb", Tablet: "LSaZgZjzA"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "C0KN0GIY2", image: wMe37a3NV = {src: new URL("assets/2048/mlKRrttrC6kgFUDF9r6l7M1DMY.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/mlKRrttrC6kgFUDF9r6l7M1DMY.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/mlKRrttrC6kgFUDF9r6l7M1DMY.jpg", import.meta.url).href} 1024w, ${new URL("assets/2048/mlKRrttrC6kgFUDF9r6l7M1DMY.jpg", import.meta.url).href} 2048w, ${new URL("assets/4096/mlKRrttrC6kgFUDF9r6l7M1DMY.jpg", import.meta.url).href} 4096w, ${new URL("assets/mlKRrttrC6kgFUDF9r6l7M1DMY.jpg", import.meta.url).href} 8513w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "C0KN0GIY2", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-nA5Lb", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 5676, intrinsicWidth: 8513, pixelHeight: 5676, pixelWidth: 8513, sizes: "1200px", ...toResponsiveImage(wMe37a3NV)}} className={cx("framer-dmis4g", className)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"C0KN0GIY2"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({LSaZgZjzA: {"data-framer-name": "Tablet", background: {alt: "", fit: "fill", intrinsicHeight: 5676, intrinsicWidth: 8513, pixelHeight: 5676, pixelWidth: 8513, sizes: "810px", ...toResponsiveImage(wMe37a3NV)}}, YMQvVxjjb: {"data-framer-name": "Phone", background: {alt: "", fit: "fill", intrinsicHeight: 5676, intrinsicWidth: 8513, pixelHeight: 5676, pixelWidth: 8513, sizes: "390px", ...toResponsiveImage(wMe37a3NV)}}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-nA5Lb [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nA5Lb .framer-14m8v74 { display: block; }", ".framer-nA5Lb .framer-dmis4g { height: 800px; overflow: hidden; position: relative; width: 1200px; }", ".framer-nA5Lb.framer-v-1abpjqe .framer-dmis4g { width: 810px; }", ".framer-nA5Lb.framer-v-h79r71 .framer-dmis4g { width: 390px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 800
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"LSaZgZjzA":{"layout":["fixed","fixed"]},"YMQvVxjjb":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"wMe37a3NV":"image"}
 */
const FrameroE4lDjmfd: React.ComponentType<Props> = withCSS(Component, css, "framer-nA5Lb") as typeof Component;
export default FrameroE4lDjmfd;

FrameroE4lDjmfd.displayName = "Hero Image";

FrameroE4lDjmfd.defaultProps = {height: 800, width: 1200};

addPropertyControls(FrameroE4lDjmfd, {variant: {options: ["C0KN0GIY2", "LSaZgZjzA", "YMQvVxjjb"], optionTitles: ["Desktop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}, wMe37a3NV: {__defaultAssetReference: "data:framer/asset-reference,mlKRrttrC6kgFUDF9r6l7M1DMY.jpg?originalFilename=a+desert+landscape+with+mountains+in+the+distance.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FrameroE4lDjmfd, [])